import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/homepage/HomePage';
import AboutPage from './components/aboutpage/AboutPage';
import PortfolioPage from './components/portfoliopage/PortfolioPage';
import ContactPage from './components/contactpage/ContactPage';
import HamburgerMenu from './components/hamburgermenu/HamburgerMenu';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import PasswordReset from './components/auth/PasswordReset';
import NewPassword from './components/auth/NewPassword';
import { AuthProvider } from './components/auth/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <HamburgerMenu />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          <Route path="/newpassword" element={<NewPassword />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
