import React, { useState } from 'react';
import { supabase } from './supabaseClient'; // Adjust the path as necessary
import { isValidEmail } from './Helper';
import styles from './Auth.module.css'; // Import the custom styles

export default function PaasswordReset() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async (e) => {
        if (!isValidEmail(email)) {
            alert('Please enter a valid email address.');
            return;
        }
        e.preventDefault();
        setLoading(true);
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://artamural.com/newpassword',
          });
        if (error) console.error('Error resetting password:', error);
        else alert('Password reset email sent!');
        setLoading(false);
    };

    return (
        <div className={styles.authContainer}>
            <h2>Reset Password</h2>
            <form onSubmit={handlePasswordReset} className={styles.authForm}>
                <input
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={styles.authInput}
                />
                <button type='submit' disabled={loading} className={styles.authButton}>
                {loading ? 'Loading...' : 'Send Reset Email'}
                </button>
            </form>
        </div>
    );
}
