import React from 'react';
import './HomePage.css'; // Import CSS file

function HomePage() {
  return (
    <div className="home-container">
      {/* Content can be added here if needed */}
    </div>
  );
}

export default HomePage;
