import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Adjust the path as necessary
import { isValidEmail } from './Helper';
import styles from './Auth.module.css'; // Import the custom styles

export default function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        e.preventDefault();
        if (!isValidEmail(email)) {
            alert('Please enter a valid email address.');
            return;
        }
        setLoading(true);
        const { error } = await supabase.auth.signInWithPassword({ email, password });
        if (error) {
            console.error('Error signing in:', error);
            alert('Error signing in');
        } else {
            navigate('/'); // Navigate to the home page after successful sign in
        }
        setLoading(false);
    };

    const handleSignInWithGoogle = async () => {
        const error = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
              queryParams: {
                access_type: 'offline',
                prompt: 'consent',
              },
            },
          })

        if (error) {
            console.error('Error signing in with Google:', error);
            alert('Error signing in with Google');
        } else {
            navigate('/'); // Navigate to the home page after successful sign in
        }
    };

    return (
        <div className={styles.authContainer}>
            <h2>Sign In</h2>
            <div className={styles.authForm}> {/* Changed from React.Fragment to div */}
                <form onSubmit={handleSignIn}>
                    <input
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.authInput}
                    />
                    <input
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={styles.authInput}
                    />
                    <button type='submit' disabled={loading} className={styles.authButton}>
                        {loading ? 'Loading...' : 'Sign In'}
                    </button>
                </form>    
    
                <Link to="/passwordreset" className={styles.authLink}>Forgot Password?</Link>
    
                <div className={styles.buttonSpacer}></div>
    
                <button onClick={handleSignInWithGoogle} disabled={loading} className={`${styles.authButton} ${styles.googleSignInButton}`}>
                    Sign In with Google
                </button>
    
                <Link to="/signup" className={styles.authLink}>Don't have an account? Sign Up</Link>
            </div>
        </div>
    );
    
}
