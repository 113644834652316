import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './AboutPage.module.css'; 

function AboutPage() {
  // Function to handle contact action
  const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    // TODO: Ensure semantic HTML and Accessibility
    <div className={styles.aboutPage}>
      <div className={styles.contentOverlay}>
        <h1>About Us</h1>
        <div className={styles.story}>
        <p className={styles.valueProposition}>  
        <strong>Tailored Artwork:</strong> Harnessing AI's creative potential, we generate art that resonates with your personal style and space requirements, ensuring each piece is as unique as you are.
        </p>
        <p className={styles.valueProposition}>
          <strong>Robotic Precision:</strong> Our state-of-the-art wall painting robot transforms AI-generated designs into reality, executing with unmatched accuracy and efficiency.
        </p>
        <p className={styles.valueProposition}>
          <strong>Simplified Selection and Quoting:</strong> We believe in simplicity. Our streamlined process for product selection and quoting makes it easy for you to choose and understand what you’re getting, without any hidden complexities.
        </p>
        <p className={styles.valueProposition}>
          <strong>Customer-Centric Approach:</strong> Your satisfaction drives us. From initial engagement to the completion of the project, we are dedicated to exceeding your expectations while respecting your privacy and space.
        </p>
        <p className={styles.valueProposition}>
          <strong>Transparent Communication:</strong> We keep you informed every step of the way. Our commitment to over-communication ensures you’re never left in the dark about your project’s progress.
        </p>
        <p className={styles.valueProposition}>
          <strong>Efficiency and Timeliness:</strong> We value your time. Our processes are designed to be efficient and unobtrusive, ensuring timely completion of projects without sacrificing the quality of our work.
        </p>
        <p className={styles.valueProposition}>
          <strong>Quality Assurance:</strong> At Arta Mural, quality is paramount. We never rush, yet remain mindful of timelines, striking the perfect balance to deliver superior results.
        </p>
        <p className={styles.valueProposition}>
          <strong>Seamless Experience:</strong> From start to finish, we handle everything. Our end-to-end service leaves no trace, just the lasting impact of beautiful, satisfying artwork.
        </p>
        </div>
        <Button onClick={handleContactClick} className={styles.contactButton}>Get In Touch</Button>
      </div>
    </div>
  );
}

export default React.memo(AboutPage);
