import React, { useState } from 'react';
import { supabase } from './supabaseClient'; // Adjust the path as necessary
import styles from './Auth.module.css'; // Import the custom styles

export default function NewPassword() {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loading, setLoading] = useState(false);

    const validatePassword = (password) => {
        const minLength = 6;
        const hasLower = /[a-z]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return (
            password.length >= minLength && hasLower && hasUpper && hasDigit && hasSymbol
        );
    };

    const handleNewPassword = async (e) => {
        e.preventDefault();
        if (!validatePassword(password)) {
            alert('Password is too weak. It must be at least 6 characters long and include lowercase and uppercase letters, digits, and symbols.');
            return;
        }
         // Check if the two passwords match
         if (password !== passwordConfirm) {
            alert('Passwords do not match.');
            return;
        }

        setLoading(true);
        const { error } = await supabase.auth.updateUser({ password: password });
        if (error) {
            console.error('Error resetting password:', error);
            alert('Error resetting password');
        } else {
            alert('Resetting password successful!');
        }
        setLoading(false);
    };

    return (
        <div className={styles.authContainer}>
            <h2>Reset Password</h2>
            <form onSubmit={handleNewPassword} className={styles.authForm}>
                <input
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={styles.authInput}
                />
                <input
                    type='password'
                    placeholder='Re-enter Password'
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    className={styles.authInput}
                />
                <button type='submit' disabled={loading} className={styles.authButton}>
                    {loading ? 'Loading...' : 'Sign Up'}
                </button>
            </form>
        </div>
    );
}
