import React, { createContext, useContext, useState } from 'react';
import { supabase } from './supabaseClient';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  // Listen for changes in authentication state
  supabase.auth.onAuthStateChange((event, session) => {
    setCurrentUser(session ? session.user : null);
  });

  const value = {
    currentUser,
    setCurrentUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
