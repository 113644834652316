import React from 'react';
import './PortfolioPage.css'; 
import Carousel from './renderCarousel'; // Adjust the import path as necessary

function PortfolioPage() {
  return (
    <div>
      <h1>Our Portfolio</h1>
      <Carousel />
    </div>
  );
}

export default PortfolioPage;
