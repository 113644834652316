import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import {SignOut } from '../auth/SignOut'
import styles from './HamburgerMenu.module.css';

function HamburgerMenu() {
  const location = useLocation();
  const { currentUser } = useAuth();

  return (
    <div>
      <div
        className={styles.menuIcon}
        style={{ color: location.pathname === '/contact' || location.pathname === '/signin'  ? 'white' : 'initial' }} // Conditional styling
      >
        ☰ {/* Hamburger icon */}
      </div>
      <div className={styles.menuList}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/contact">Contact</Link>
        {/* Dynamically display Sign In or Sign Out */}
        {currentUser ? (
          // <a href="javascript:void(0)" onClick={SignOut}>SignOut</a>
          <button type="button" onClick={SignOut} className={styles.linkStyleButton}>SignOut</button>

        ) : (
          <Link to="/signin">SignIn</Link>
        )}
      </div>
    </div>
  );
}

export default HamburgerMenu;
