import React, { useState } from 'react';
import styles from './ContactPage.module.css';

function ContactPage() {
  const [expandProjectDescription, setExpandProjectDescription] = useState(false);

  const handleProjectDescriptionClick = () => {
    setExpandProjectDescription(true);
  };

  const handleFieldBlur = () => {
    setExpandProjectDescription(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Form submission logic here
  };

  return (
    <div className={styles.contactPageContainer}>
      <h1>Contact Us</h1>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <label>
          First Name
          <input type="text" placeholder="First Name" />
        </label>
        <label>
          Last Name
          <input type="text" placeholder="Last Name" />
        </label>
        <label>
          Your Email
          <input type="email" placeholder="Your Email" />
        </label>
        <label>
          Describe your project
          <textarea 
            placeholder="Describe your project" 
            onClick={handleProjectDescriptionClick} 
            onBlur={handleFieldBlur}
            className={expandProjectDescription ? styles.expanded : ""}
          />
        </label>
        <label>
          Timelines
          <textarea placeholder="Timelines (describe in your own words)" />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactPage;
