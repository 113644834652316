import React, { useRef } from 'react';
import './PortfolioPage.css'; 
import portfolioAssets from '../../resources/portfolio/portfolioAssets.json'; // Adjust the path as necessary

function Carousel() {
  const carouselRef = useRef(null);

  const handleLeftArrowClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= 200; // Adjust scroll amount as necessary
    }
  };

  const handleRightArrowClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += 200; // Adjust scroll amount as necessary
    }
  };

  return (
    <div className="carousel-container" ref={carouselRef}>
      <button onClick={handleLeftArrowClick} className="carousel-arrow left">←</button>
      {portfolioAssets.map(asset => (
        <div key={asset.id} className="carousel-item">
          <img src={require(`../../resources/portfolio/${asset.path}`)} alt={asset.imageName} />
          <p>{asset.description}</p>
        </div>
      ))}
      <button onClick={handleRightArrowClick} className="carousel-arrow right">→</button>
    </div>
  );
}

export default Carousel;
